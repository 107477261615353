import React from 'react';
import { useState, useEffect } from 'react';

export default function Raschet() {
    const [p, setP] = useState('');
    const [t, setT] = useState('');
    const [selectPodlozhka, setPodlozhka] = useState('copper');
    const [t_real_text, setT_real_text] = useState('');
    const [recomend1, setRecomend1] = useState('');
    const [recomend2, setRecomend2] = useState('');
    const [recomend3, setRecomend3] = useState('');
    const [poluchenie, setPoluchenie] = useState('');

    useEffect(() => {
        editText(p, t, selectPodlozhka);
    }, [p, t, selectPodlozhka]);

    const handleChangeP = (event) => {
        setP(event.target.value = event.target.value.replace(/[^0-9.]/g, ''));
    };
    const handleChangeT = (event) => {
        setT(event.target.value = event.target.value.replace(/[^0-9.]/g, ''));
    };
    const handleChangePodlozhka = (event) => {
        setPodlozhka(event.target.value);
    };

    function editText(p, t, selectPodlozhka) {
        var kon = '';
        var t_real = 1.1;
        switch (selectPodlozhka) {
            case 'copper':
                var x = 0.1;
                var t_kr = 1358;
                printus(p, t);
                break;
            case "molybdenum":
                x = 0.3;
                t_kr = 2893;
                printus(p, t);
                break;
            case "diamond":
                x = 0.5;
                t_kr = 3973;
                printus(p, t);
                break;
            case "silicon":
                x = 0.2;
                t_kr = 1687;
                printus(p, t);
                break;
            case "tungsten":
                x = 0.3;
                t_kr = 3695;
                printus(p, t);
                break;
            default:
                setT_real_text('fatal');
                break;
        }

        function printus(p, t) {
            t_real = t - t * x;
            setT_real_text(`Tреал = ${t} - ${t} × ${x} = ${t_real}`);
            kon = f_type(p, t_real);
            if (Number(t_real) > Number(t_kr)) {
                setRecomend2('Данная подложка не подходит из-за температурного режима.');
            } else {
                setRecomend2('');
            }
            f_recomend(p);
            setPoluchenie(kon);
        }

        function f_recomend(p) {
            switch (true) {
                case Number(p) > 300000:
                    setRecomend3('Понизьте давление до 50-150 кБар.');
                    break;
                case Number(p) < 500:
                    setRecomend3('Повысьте давление до 500 Бар.');
                    break;
                default:
                    setRecomend3('');
                    break;
            }
        }

        function f_type(p, t_real) {
            p = Number(p);
            t_real = Number(t_real);
            switch (true) {
                case (t_real > 4000):
                    setRecomend1('Понизьте температуру так как алмаз начнёт переходить в графит.');
                    kon = "Графит";
                    break;
                case (p < 5000 && t_real > 900 && t_real < 1400):
                    kon = "Синтез алмаза химическим напылением";
                    break;
                case (p > 50000 && p < 100000 && t_real > 1400 && t_real < 2400):
                    kon = "Каталитичесикй синтез алмаза под высоким давлением";
                    break;
                case (p > 120000 && p < 170000 && t_real > 2600 && t_real < 3400):
                    kon = "Высокотемпературный синтез алмаза под высоким давлением";
                    break;
                case (p > 290000 && p < 400000 && t_real > 1000 && t_real < 2200):
                    kon = "Ультрадисперсный детонационный алмаз";
                    break;
                default:
                    setRecomend1('');
                    kon = "Алмаз с сбольшим содержаинем графитовой фазы";
                    break;
            }
            return kon;
        }
    }

    return (
        <div className='soder2'>
            <p>P = <input name="p" size="6" type="text" onChange={handleChangeP} /> Бар</p>
            <p>T = <input name="t" size="6" type="text" onChange={handleChangeT} /> К</p>
            <p>Материал подложки:</p>
            <p><select id="Podlozhka" value={selectPodlozhka} onChange={handleChangePodlozhka}>
                <option value="copper">Медь</option>
                <option value="molybdenum">Молибден</option>
                <option value="diamond">Алмаз</option>
                <option value="silicon">Кремний</option>
                <option value="tungsten">Вольфрам</option>
            </select></p>
            <p>
                С учётом теплоотвода:<br />T<sub>реал</sub> = T - T &#215; X
                <div>
                    {t_real_text}
                </div>
            </p>
            <p>
                При таких параметрах вы получите:<br />
                <div>
                    {poluchenie}
                </div>
            </p>
            <p>
                Рекомендации по оптимизации для получения качественного алмаза:
                <div>
                    {recomend1}<br />
                    {recomend2}<br />
                    {recomend3}
                </div>
            </p>
        </div>
    );
}

