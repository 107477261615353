import React, { } from "react";
import {
    Container,
    Nav,
    Navbar,
} from "react-bootstrap";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from '../Pages/Home';
import About from '../Pages/Raschet';

export default function Header() {
    return (
        <div>
            <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto my-2 my-lg-0">
                            <Nav.Link href="/"> Главная </Nav.Link>
                            <Nav.Link href="/raschet"> Расчёт </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Raschet" element={<About />} />
                    <Route path="*" element={<div className='soder1'>Страница не найдена, проверьте корректность запроса</div>} />
                </Routes>
            </Router>
        </div>
    );
}
