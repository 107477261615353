import React from 'react';
import img1 from '../Components/img1.jpg';

export default function About() {
    return (
        <div className='soder1'>
            <h2>Расчет параметров химического процесса получения алмаза по фазовой диаграмме состояния углерода</h2>
            <img src={img1} className="img" alt="graph" />
            <p>
                Методика CVD позволяет наращивать твердые вещества на поверхности с помощью химических
                превращений паров летучих веществ. Осаждение из газовой фазы протекает через ряд последовательных
                стадий: испарение, перенос, зародышеобразование и рост; последняя стадия тесно связана со структурой
                осадка и напряжениями в нём. Для каждого из указанных процессов разрабатывается специализированное
                оборудование.
                При химическом осаждении имеет место рассеяние материала, что позволяет получить равномерные осадки на
                изделиях относительно сложной формы.<br />С помощью данного алгоритма вы сможете заранее рассчитать исход
                эксперимента при заданных вами параметрах,а также получить рекомендации по тому как оптимизировать ваши
                параметры под получение непосредственно алмаза в хорошем качестве.
            </p>
        </div>
    );
}
